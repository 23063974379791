import React, { useState } from "react";
import {
  FaTimes,
  FaBars,
  FaHome,
  FaBuilding,
  FaUserFriends,
  FaGavel,
  FaFileAlt,
  FaRegHeart,
  FaCog,
} from "react-icons/fa";
import {  useRef, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {

 
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null); // Reference to the sidebar container

  const toggletypeSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Close the sidebar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false); // Close the sidebar
      }
    };

    // Add event listener to detect clicks outside the sidebar
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to toggle the open/close state
 
  return (
    <>
      <header>
        <div className="container">
          <nav>
          <div>
      {/* Sidebar toggle button */}
      <button className="sidebar-toggle-btn" onClick={toggletypeSidebar}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Sidebar */}
      <div ref={sidebarRef} className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="sidebar-links">
        <Link to="/" style={{ textDecoration: "none" }}>
        <div className="addproperty-text">
          <a href="#" className="sidebar-link active">
            <FaHome />
                <span style={{ display: "flex", color: "black" }}>Home</span>
          </a>
          </div>
          </Link>
          <Link to="/AddProperty" style={{ textDecoration: "none" }}>
          <div className="addproperty-text">
          <a href="#" className="sidebar-link">
            <FaBuilding />
                <span style={{ display: "flex", color: "black" }}>Add Property</span>
          </a>
          </div>
          </Link>
          <a href="#" className="sidebar-link">
            <FaBuilding /> Properties
          </a>
         
          <Link to="/agents" style={{ textDecoration: "none" }}>
          <div className="addproperty-text">
          <a href="#" className="sidebar-link">
            <FaUserFriends />
           
                <span style={{ display: "flex", color: "black" }}>Agents</span>
             
          </a>
          </div>
          </Link>
         
          <Link to="/lawyers" style={{ textDecoration: "none" }}>
          <div className="addproperty-text">
          <a href="#" className="sidebar-link">
            <FaGavel />
           
                <span style={{ display: "flex", color: "black" }}>Lawyers</span>
            
          </a>
          </div>
          </Link>
          
          <Link to="/loans" style={{ textDecoration: "none" }}>
          <div className="addproperty-text">
          <a href="#" className="sidebar-link">
            <FaFileAlt />
           
                <span style={{ display: "flex", color: "black" }}>Loans</span>
          
          </a>
          </div>
          </Link>
        
              <Link to="/favourites" style={{ textDecoration: "none" }}>
              <div className="addproperty-text">
          <a href="#" className="sidebar-link">
            <FaRegHeart />
          
                <span style={{ display: "flex", color: "black" }}>Favorites</span>
            
          </a>
          </div>
          </Link>
          <a href="#" className="sidebar-link">
            <FaCog /> Settings
          </a>
        </div>
      </div>
    </div>
            <div className="motion-reduce">
              <img
                className="logo header-logo"
                src="./images/logo.png"
                alt="YidEstate"
              />
            </div>
          </nav>
        </div>
      </header>

  

     


    </>
  );
};

export default Header;
