import React from "react";
import { NavLink } from "react-router-dom"; // Use NavLink instead of Link
import { FiSearch } from "react-icons/fi";
import {
  FaUsers,
  FaBalanceScale,
  FaFileAlt,
  FaHeart,
  FaUser,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="dev-stickyfooter">
      <div className="footer-items">
        {/* Search Icon */}
        <NavLink
          to="/"
          exact
          className="item no-underline"
          activeClassName="active"
        >
          <div className="icon">
            <FiSearch size={24} />
          </div>
          <p>Search</p>
        </NavLink>

        {/* Agents Icon */}
        <NavLink
          to="/agents"
          className="item no-underline"
          activeClassName="active"
        >
          <div className="icon">
            <FaUsers size={24} />
          </div>
          <p>Agents</p>
        </NavLink>

        {/* Lawyers Icon */}
        <NavLink
          to="/lawyers"
          className="item no-underline"
          activeClassName="active"
        >
          <div className="icon">
            <FaBalanceScale size={24} />
          </div>
          <p>Lawyers</p>
        </NavLink>

        {/* Loans Icon (File Icon) */}
        <NavLink
          to="/loans"
          className="item no-underline"
          activeClassName="active"
        >
          <div className="icon">
            <FaFileAlt size={24} />
          </div>
          <p>Loans</p>
        </NavLink>

        {/* Favourites Icon */}
        <NavLink
          to="/Favourite"
          className="item no-underline"
          activeClassName="active"
        >
          <div className="icon">
            <FaHeart size={24} />
          </div>
          <p>Favorites</p>
        </NavLink>

        {/* Profile Icon */}
        <NavLink
          to="/profile"
          className="item no-underline"
          activeClassName="active"
        >
          <div className="icon">
            <FaUser size={24} />
          </div>
          <p>Profile</p>
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
