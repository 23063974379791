import React, { useState } from 'react';
import { IoPricetagOutline } from "react-icons/io5";
 import { FaRulerCombined } from "react-icons/fa";
 import { IoBedOutline } from "react-icons/io5";
 import { LiaBathSolid } from "react-icons/lia";
 import { MdPool } from "react-icons/md";
 import { FaRegKeyboard } from "react-icons/fa6";


const FiltersModal = () => {
  const [priceRange, setPriceRange] = useState([0, 1000000]);
  const [areaRange, setAreaRange] = useState([0, 500000]);
  const [selectedBedrooms, setSelectedBedrooms] = useState(1);
  const [selectedBathrooms, setSelectedBathrooms] = useState(1);
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [keyword, setKeyword] = useState("65");
  const [poolOption, setPoolOption] = useState("All");
  const [isOpen, setIsOpen] = useState(false);

  const handlePoolButtonClick = (option) => {
    setPoolOption(option);
  };

  const handleReset = () => {
    setKeyword("65");
    setPoolOption("All");
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div id="customModal" className="custom-filters-popup">
      <div id="myModal" className="modal">
        {/* Modal content */}
        <div className="modal-content">
        <div className="modal-header">
        <button className="close" onClick={handleClose}>
                  &times;
                </button>
              <h2>Filters</h2>
            </div>
          <div className="modal-body">
            {/* I want to section */}
            <div className="modal-filter">
              <div className="heading">
                <div className="icon">
                  {/* SVG Icon */}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25" height="25">
                    <path fill="#ff0000" d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"></path>
                  </svg>
                </div>
                <p className="text">I want to</p>
              </div>
              <div className="content">Content</div>
            </div>

            {/* Location Section */}
            <div className="modal-filter" id="locator">
              <div className="heading">
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill="#ff0000" width="25">
                    <path fill="#ff0000" d="M32,0C18.746,0,8,10.746,8,24c0,5.219,1.711,10.008,4.555,13.93l16,24C29.414,63.332,30.664,64,32,64s2.586-0.668,3.328-1.781l16-24C54.289,34.008,56,29.219,56,24C56,10.746,45.254,0,32,0z M32,32c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S36.418,32,32,32z"></path>
                  </svg>
                </div>
                <p className="text"><span className="highlight">Location</span><span className="main">Please Select</span></p>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="25" height="25">
                <path fill="#ff0000" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
              </svg>
            </div>

            {/* Property Types */}
            <div className="modal-filter">
              <div className="heading">
                <div className="icon">
                  <svg fill="#ff0000" viewBox="0 -4.91 122.88 122.88" xmlns="http://www.w3.org/2000/svg" width="25">
                    <path d="M0,100.07h14.72V1.57c0-0.86,0.71-1.57,1.57-1.57h49.86c0.86,0,1.57,0.71,1.57,1.57V38.5h44.12c0.86,0,1.57,0.71,1.57,1.57v59.99h9.47v12.99H0V100.07L0,100.07z"></path>
                  </svg>
                </div>
                <p className="text">Property Types</p>
              </div>
              <div className="content">Content</div>
            </div>

            {/* Price Range */}
            <div className="modal-filter" id="price-range">
              <div className="heading">
                <div className="icon">
                <IoPricetagOutline style={{width:'25px', height:"25px",display:"flex", color:"red"}}/>
                </div>
                <p className="text">Price Range</p>
              </div>
              <div className="range-inputs">
     <input type="text" value={priceRange[0]} onChange={(e) => setPriceRange([+e.target.value, priceRange[1]])}/>
     <span>
      TO
     </span>
     <input type="text"  value={priceRange[1]}
                  onChange={(e) => setPriceRange([priceRange[0], +e.target.value])}/>
    </div>
              <div className="content">
                <input
                  type="range"
                  min="0"
                  max="1000000"
                  value={priceRange[0]}
                  onChange={(e) => setPriceRange([+e.target.value, priceRange[1]])}
                />
                <input
                  type="range"
                  min="0"
                  max="1000000"
                  value={priceRange[1]}
                  onChange={(e) => setPriceRange([priceRange[0], +e.target.value])}
                />
                {/* <div>{priceRange[0]} TO {priceRange[1]}</div> */}
              </div>
            </div>

            {/* Area Range */}
            <div className="modal-filter" id="area-range">
              <div className="heading">
                <div className="icon">
                <FaRulerCombined style={{width:'25px', height:"25px",display:"flex", color:"red"}}/>

                </div>
                <p className="text">Area Range</p>
              </div>
              <div className="range-inputs">
     <input type="text"  value={areaRange[0]}
                  onChange={(e) => setAreaRange([+e.target.value, areaRange[1]])}/>
     <span>
      TO
     </span>
     <input type="text"   value={areaRange[1]}
                  onChange={(e) => setAreaRange([areaRange[0], +e.target.value])}/>
    </div>
              <div className="content">
                <input
                  type="range"
                  min="0"
                  max="5000"
                  value={areaRange[0]}
                  onChange={(e) => setAreaRange([+e.target.value, areaRange[1]])}
                />
                <input
                  type="range"
                  min="0"
                  max="5000"
                  value={areaRange[1]}
                  onChange={(e) => setAreaRange([areaRange[0], +e.target.value])}
                />
                {/* <div>{areaRange[0]} TO {areaRange[1]}</div> */}
              </div>
            </div>

            {/* Bedrooms */}
      
      {/* Bedrooms Section */}
      <div className="filter-item">
      <div className="heading">
                <div className="icon">
                <IoBedOutline style={{width:'25px', height:"25px",display:"flex", color:"red"}}/>

                </div>
                <p className="text">Bedrooms</p>
              </div>
        <div className="button-group">
          {[1, 2, 3, 4, 5, 6].map((bedroom) => (
            <button
              key={bedroom}
              className={`btn ${bedrooms === bedroom ? "selected" : ""}`}
              onClick={() => setBedrooms(bedroom)}
            >
              {bedroom}
            </button>
          ))}
        </div>
      </div>

      {/* Bathrooms Section */}
      <div className="filter-item">
      <div className="heading">
                <div className="icon">
                <LiaBathSolid style={{width:'25px', height:"25px",display:"flex", color:"red"}}/>

                </div>
                <p className="text">Bathrooms</p>
              </div>
        <div className="button-group">
          {[1, 2, 3, 4, 5, 6].map((bathroom) => (
            <button
              key={bathroom}
              className={`btn ${bathrooms === bathroom ? "selected" : ""}`}
              onClick={() => setBathrooms(bathroom)}
            >
              {bathroom}
            </button>
          ))}
        </div>
      </div>
      <div className="container_app_type">
      {/* Pool Section */}
      <div className="section">
      <div className="heading">
                <div className="icon">
                <MdPool style={{width:'25px', height:"25px",display:"flex", color:"red"}}/>

                </div>
                <p className="text">pool</p>
              </div>
        <div className="button-group">
          {["All", "Yes Pool", "No Pool"].map((option) => (
            <button
              key={option}
              className={poolOption === option ? "active" : ""}
              onClick={() => handlePoolButtonClick(option)}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      {/* Keyword Section */}
      <div className="section">
      <div className="heading">
                <div className="icon">
                <FaRegKeyboard style={{width:'25px', height:"25px",display:"flex", color:"red"}}/>

                </div>
                <p className="text">Keywords</p>
              </div>
        <div className="input-group">
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
      </div>

      {/* Action Buttons */}
      <div className="action-buttons">
        <button className="reset" onClick={handleReset}>
          Reset
        </button>
        <button className="search">
          Search
        </button>
      </div>
    </div>
          </div>

          {/* <div className="modal-footer">
            <button className="apply-btn">Apply</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;
