import React from 'react'
const PropertyResult = () => {
    const properties = [
        {
          image:
            "https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2",
          price: "$200K",
          beds: 2,
          baths: 2,
          sqft: 1500,
          address: "355 s Oxnard blvd",
        },
        {
          image:
            "https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2",
          price: "$200K",
          beds: 2,
          baths: 2,
          sqft: 1500,
          address: "355 s Oxnard blvd",
        },
        {
          image:
            "https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2",
          price: "$200K",
          beds: 2,
          baths: 2,
          sqft: 1500,
          address: "355 s Oxnard blvd",
        },
        {
          image:
            "https://www.telegraph.co.uk/content/dam/Travel/hotels/2020/march/soneva-jani-water-retreat--bedroom-with-sea-view-pool-by-.jpg?imwidth=1280&imdensity=2",
          price: "$200K",
          beds: 2,
          baths: 2,
          sqft: 1500,
          address: "355 s Oxnard blvd",
        },
      ];
  return (
    <div className="property-results customPageWidth section-margin">
     
    <div class="featured-properties-app">
    <h2 style={{marginBottom:"12px"}}>

    </h2>
</div>
    <div className="property-items">
      {properties.map((property, index) => (
        <div key={index} className="item">
          <div className="property-image">
            <img src={property.image} />
          </div>
          <div className="property-info">
            <p className="price">{property.price}</p>
            <div className="descriptive">
              <span>{property.beds} Bed</span>
              <span>{property.baths} Bath</span>
              <span>{property.sqft} sq ft</span>
            </div>
            <p className="address">{property.address}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
  )
}

export default PropertyResult