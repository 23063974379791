import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async () => {
    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      };
      const response = await fetch('https://ae4f-139-135-43-100.ngrok-free.app/auth/login', requestOptions);

      // const backendUrl = process.env.REACT_APP_BACKEND_URL;
      // const response = await fetch(`${backendUrl}/auth/login`, requestOptions);

   
      if (response.ok) {
        debugger;
        const result = await response.json();
        console.log("Login successful", result);

        localStorage.setItem("bearer-token", result.token);

        setSuccess("Login successful!");
        debugger;
        setSuccess(result.message);
        setError(null);
        setUsername("");
        setPassword("");
        navigate("/PropertyResult");
      } else {
        debugger;
        const result = await response.text();
        setError(result);
        setSuccess(null);
      }
    } catch (error) {
      debugger;
      setError("An error occurred. Please try again later.");
      console.error(error);
    }
  };

  return (
    <div className="login-page customPageWIdth section-margin" id="userLogin">
      <div className="form-wrapper">
        <div className="form-control">
          <label className="form-label" htmlFor="username">
            Username
          </label>
          <input
            className="form-input"
            id="username"
            type="text"
            placeholder="Enter the Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="form-label" htmlFor="password">
            Password
          </label>
          <input
            className="form-input"
            id="password"
            type="password"
            placeholder="Enter Your Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-control">
          <button type="button" className="form-submit" onClick={handleLogin}>
            Login
          </button>
        </div>

        {error && <p style={{ color: "red", textAlign: "center" }}>{error}</p>}
        {success && (
          <p style={{ color: "green", textAlign: "center" }}>{success}</p>
        )}

        <p className="center-text">
          Do not have an account?{" "}
          <Link to="/signup" className="red-link">
            <span>Sign Up</span>
          </Link>
        </p>
        <p className="center-text">
          <a href="#" className="red-link">
            Forgot Password
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
