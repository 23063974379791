import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

const ProfilePage = () => {
  return (
    <div className="container-page">
      <div className="profile-header">
        <img
          alt="Profile"
          height="100"
          className="Image"
          src="https://storage.googleapis.com/a1aa/image/nkdOvB3GY17wA9vY1tpNy6zdrMr3sPW7dLM4F5fwIL5XH0yJA.jpg"
          width="80"
        />
        <div className="info-page">
          <h1>Mark Gold</h1>

          <h2>EZ Properties Bros</h2>
          <div className="page-app">
            <p>
              <FaMapMarkerAlt />
              123
            </p>
            <p>Ez</p>
            <p>Street</p>
            <p>Lakewood NJ 08701</p>
          </div>
        </div>
      </div>

      <div className="license">
        <p className="l-page">License: </p>
        <p>L-983734</p>
      </div>

      <div className="about">
        <h3>About</h3>
        <p>
          I'm Mark, a dedicated real estate agent specializing in residential
          and commercial properties. With a keen eye for detail and a co...
        </p>
        <span className="read-more">Read More</span>
      </div>

      <div className="review">
        <h3>Give a review</h3>
        <p>Tell others what you think</p>
        <div className="stars">
          <FaStar style={{ color: "#ccc", width: "25px", height: "25px" }} />
          <FaStar style={{ color: "#ccc", width: "25px", height: "25px" }} />
          <FaStar style={{ color: "#ccc", width: "25px", height: "25px" }} />
          <FaStar style={{ color: "#ccc", width: "25px", height: "25px" }} />
          <FaStar style={{ color: "#ccc", width: "25px", height: "25px" }} />
        </div>
        <span className="write-review">Write a review</span>
      </div>

      <div className="contact">
        <div className="button email">
          <FaEnvelope /> EMAIL
        </div>
        <div className="button call">
          <FaPhoneAlt />
          CALL
        </div>
        <div className="button whatsapp">
          <FaWhatsapp />
          WhatsApp
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
