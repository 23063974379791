import React from "react";

import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { FaRegStar } from "react-icons/fa6";
import { GoTag } from "react-icons/go";

import { FaArrowLeft } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { FaShareAlt } from "react-icons/fa";
import { FaPrint } from "react-icons/fa";
import { FaFlag } from "react-icons/fa";
import { FaRulerCombined } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { FaSnowflake } from "react-icons/fa";
import { FaTv } from "react-icons/fa";
import { FaWifi } from "react-icons/fa";
import { LuRefrigerator } from "react-icons/lu";
import { FaWindowMaximize } from "react-icons/fa";
import { IoIosStar } from "react-icons/io";
import { IoChevronForward } from "react-icons/io5";
import { FaRegEnvelope } from "react-icons/fa";
import { LuPhone } from "react-icons/lu";
import { IoBedOutline } from "react-icons/io5";
import { FaBath } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";

const DetailProperty = () => {
  return (
    <>
    <div className="container-apps">
    <div className="image-container">
      <img
        src="https://storage.googleapis.com/a1aa/image/BfTHQoBY3cSJb652emjsNYcDAW1tBqg7bbHJKat4rnoitxiTA.jpg"
        alt="Modern office space with a desk, chairs, and wall art"
        width="600"
        height="400"
      />
     
      
      <div className="buttons">
    <div className="button">
    <FaArrowLeft />
    
    </div>
    <di className="icon-app">
    <div className="button">
    <CiHeart />
    </div>
    <div className="button">
    <FaShareAlt />
    </div>
    <div className="button">
    <FaPrint />
    </div>
    <div className="button">
    <FaFlag />
    </div>
    </di>
   </div>
    </div>
    
    <div className="property-details">
      <h1>Modern office space</h1>
      <div className="tags">
        <div className="tag">Featured</div>
        <div className="tag">For Rent</div>
      </div>
      <div className="price">$1,900/mo</div>
      <div className="info">
        <div className="info-item">
        <FaRulerCombined  className="i-cloud"/>
          <span>1900 Sq Ft</span>
        </div>
        <div className="info-item">
        <FaCalendarAlt className="i-cloud"/>
          <span>2016 Year built</span>
        </div>
      </div>
    </div>
    <div className="details">
     
      <div className="header-plans">
          <div className="title">Details</div>
         
          <a className="link" href="#">More Details </a>
        </div>
      <table className="details-table">
        <tbody>
          <tr>
            <th>Created Date :</th>
            <td>March 7, 2016</td>
          </tr>
          <tr>
            <th>Last Updated :</th>
            <td>September 19, 2023</td>
          </tr>
          <tr>
            <th>Property ID :</th>
            <td>416</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="features">
     
      <div className="header-plans">
          <div className="title">Features</div>
          {/* <a href="#"></a> */}
          <a className="link" href="#">Show More </a>
        </div>
      <div className="features-list">
        <div className="feature-item">
          <div className="feature-item-app">
        <FaSnowflake />
        </div>
          <span>Air Condit</span>
        </div>
        <div className="feature-item">
        <div className="feature-item-app">
         <FaTv />
        </div>
       
          <span>TV Cable</span>
        </div>
        <div className="feature-item">
       
        <div className="feature-item-app">
        <FaWifi />
        </div>
          <span>WiFi</span>
        </div>
        <div className="feature-item">
       
        <div className="feature-item-app">
        <LuRefrigerator />
        </div>
          <span>Refrigerat</span>
        </div>
        <div className="feature-item">
        
        <div className="feature-item-app">
        <FaWindowMaximize />
        </div>
          <span>Window</span>
        </div>
      </div>
    </div>
    <div className="section-title">Description</div>
      <div className="description">
        This fun spot features a charming, bright, and airy living and peaceful office space in the unit.
        Unit #5 is the largest floor plan of the boutique project, with 10 total units. The rear corner
        unit with only one shared wall; the space is perfect for enjoying the greenery, filtered light, and quiet.
      </div>
      <div className="section-title-cards">
      <div className="section-title">Address</div>
      <a className="map-link" href="#">Open in Map &gt;</a>
      </div>
      <div className="address-app">
        2208 Southwest Dr, Los Angeles, CA 90043, USA
      </div>

      <div className="address-details">
        <div>
          <strong>City:</strong>
          <br />
          Los Angeles
        </div>
        <div>
          <strong>Area:</strong>
          <br />
          Hyde Park
        </div>
        
      </div>

      <div className="address-details-app">
          <strong>State:</strong>
          <br />
          California
        </div>
      <img
        className="map"
        src="https://storage.googleapis.com/a1aa/image/X1n1XF8dSHbLHFnGnYZilM4nNzh0XQWie5cdhLuNwYd6QcxJA.jpg"
        alt="Map showing the location of 2208 Southwest Dr, Los Angeles, CA 90043, USA"
        width="600"
        height="300"
      />

    <div className="floor-plan">
    <div className="header-plans">
          <div className="title">Second Floor</div>
          {/* <a href="#"></a> */}
          <a className="link" href="#">View Floor Plan &gt;</a>
        </div>
        <div className="header-plans">
        <div className="i">
          <div>
        <IoBedOutline />
        </div>
        <div className="text-feild-plan">
          543 Sqft
        </div>
        </div>
        <div className="i">
        <div>
        <FaBath />
        </div>
        <div className="text-feild-plan">
          238 Sqft
        </div>

        </div>
        </div>
        <div className="header-plans">
          <div className="i">
        <div>
        
        <GoTag />
        </div>
        <div className="text-feild-plan">
          1345 Sqft
        </div>
        </div>
        <div className="i">
        <div>
        <FaRulerCombined />
        </div>
        <div className="text-feild-plan">
          238 Sqft
        </div>
        </div>
        </div>
     
     
       
      </div>

      <div className="contact-info">
        <h2>Contact Information</h2>
        <div className="agency">
          <img
            src="https://storage.googleapis.com/a1aa/image/GGXCAAzZ7iLmCd4b4ak9FxGuqvXSy546t5ees71Rdlslv4iTA.jpg"
            alt="Modern House Real logo"
            width="50"
            height="50"
          />
          <div>
            <h3>Modern House Real</h3>
            <p>Agency</p>
          </div>
          <div className="contact-icons">
            <a href="#" className="email">
            <FaRegEnvelope />
            </a>
            <a href="#" className="phone">
            <LuPhone />
            </a>
          </div>
        </div>

        <ul className="options">
          <li>
            <a href="#">Enquire Info</a>
            <IoChevronForward />
          </li>
          <li>
            <a href="#">Setup Tour</a>
            <IoChevronForward />
          </li>
          <li>
            <a href="#">Watch Video</a>
            <IoChevronForward />
          </li>
          <li>
            <a href="#">Virtual Tour</a>
            <IoChevronForward />
          </li>
        </ul>
      </div>

      <div className="reviews">
        <h2>Reviews</h2>
        <div className="overall-rating">
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        
          <span>(5 out of 5)</span>
        </div>
        <div className="review-card">
        <div className="header">
          <div className="title">Modern office</div>
          <div className="date">about a month ago</div>
        </div>
        <div className="content">
          <div className="star-app">
          <div className="stars">
          <IoIosStar />
          <IoIosStar />
          <IoIosStar />
          <IoIosStar />
          <IoIosStar />
          <IoIosStar />
           </div>
           <div>
           <h2 className="footer">Suleman Saleem</h2>
           </div>
          </div>
          <div className="text">Office is also quite modern and spacious</div>
        </div>
       
      </div>

      
      </div>
      <div className="review-card">
        <div className="header">
          <div className="title">Testing</div>
          <div className="date">3 months ago</div>
        </div>
        <div className="content">
          <div className="star-app">
          <div className="stars">
            <IoIosStar />
            <IoIosStar />
           <FaStarHalfAlt />
           <FaRegStar />
           <FaRegStar />
           </div>
           <div>
           <h2 className="footer">Jurry Abbas</h2>
           </div>
          </div>
          <div className="text">Testing</div>
        </div>
       
      </div>

      <div className="review-card">
        <div className="header">
          <div className="title">I like it</div>
          <div className="date">3 months ago</div>
        </div>
        <div className="content">
        <div className="star-app">
          <div className="stars">
          <IoIosStar />
            <IoIosStar />
           
           <IoIosStar />
           <FaStarHalfAlt />
           <FaRegStar />
           </div>
           <div>
           <h2 className="footer">Misbah Fatima</h2>
           </div>
          </div>
          
          <div className="text">Is it available right now?</div>
        </div>
        
      </div>

      <div className="links">
        <a className="link" href="#">Write a review</a>
        <a className="link" href="#" style={{ float: 'right' }}>View all</a>
      </div>
      <br></br>
      <div>

      <h2>Related Properties</h2>
      </div>
      <div className="property-card">
        <div className="header">
          <div className="image">
            <img
              src="https://storage.googleapis.com/a1aa/image/Uf2fGtEiF8mPSUOficcrRrZszhGH4Ps8dZogeAsR5mNqEyLOB.jpg"
              alt="Image of a renovated office with modern furniture"
            />
          </div>
          <div className="details">
            <div className="title">Ample renovated office</div>
            <div className="tags">
              <span className="tag">For Sale</span>
            </div>
            <div className="info-app">
              <div>
              <LiaMapMarkerAltSolid className="i-cloud"/>
                5760 Crenshaw Blvd, Los Angeles
              </div>
              <div>
              <FaRulerCombined className="i-cloud"/>
                2100 Sq Ft
              </div>
              <div className="link-price">
                <div>
              <a className="link" href="#">OFFICE</a>
              </div>
              <div className="price">$245K</div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div className="property-card">
        <div className="header">
          <div className="image">
            <img
              src="https://storage.googleapis.com/a1aa/image/jFnLYL1frbRrbKgrxNkS3u07fpn4AMXw1IjgcCBebrQZC5FnA.jpg"
              alt="Image of an office in downtown with modern furniture"
            />
          </div>
          <div className="details">
            <div className="title">Office in downtown</div>
            <div className="tags">
              <span className="tag">Featured</span>
              <span className="tag">For Rent</span>
            </div>
            <div className="info-app">
              <div>
              <LiaMapMarkerAltSolid className="i-cloud"/>
                1417 Glendale Blvd, Los Angeles
              </div>
              <div>
              <FaRulerCombined className="i-cloud"/>
                3100 Sq Ft
              </div>
              <div className="link-price">
                <div>
                <a className="link" href="#">COMMERCIAL</a>
                </div>
                <div className="price">$9K/mo</div>
                </div>
            </div>
            
          </div>
        </div>
      </div>
  
    <div className="contact-buttons">
      <div className="contact-button email">EMAIL</div>
      <div className="contact-button call">CALL</div>
    </div>
  </div>
  </>
  )
}

export default DetailProperty