import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

const Upload = () => {
  const [formErrors, setFormErrors] = useState({});
  const [inputFields, setInputFields] = useState([{ image: null }]);
  const [formData, setFormData] = useState({
    select_and_upload: '',
    videoUrl: '',
  });
  const [success, setSuccess] = useState(null);
  const [fetchedData, setFetchedData] = useState(null); // State for storing fetched data
  const navigate = useNavigate();

  // Function to fetch data (GET request)
  const fetchData = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem("bearer-token"),
      }
    };

    try {
      const response = await fetch('https://79ac-139-135-43-100.ngrok-free.app/api/data', requestOptions);
      if (response.ok) {
        const result = await response.json();
        setFetchedData(result);
        console.log('Fetched data:', result);
      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setInputFields([{ image: file }]);
    setFormData((prevData) => ({
      ...prevData,
      select_and_upload: event.target.value,
    }));
  };

  const handleVideoUrlChange = (event) => {
    setFormData((prevData) => ({ ...prevData, videoUrl: event.target.value }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.select_and_upload) errors.select_and_upload = "Please upload an image.";
    if (!formData.videoUrl) errors.videoUrl = "Video URL is required.";
    return errors;
  };

  const handleNext = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      const formDataObj = new FormData();
      formDataObj.append('select_and_upload', formData.select_and_upload);
      formDataObj.append('image', inputFields[0].image);
      formDataObj.append('videoUrl', formData.videoUrl);
      formDataObj.append('property_id', localStorage.properties);
      formDataObj.append('user_id', JSON.parse(localStorage.user)._id);
       debugger;
      const requestOptions = {
        method: "POST",
        body: formDataObj,
        headers: {
          "Authorization": 'Bearer ' + localStorage.getItem("bearer-token"),
        }
      };
      debugger;
      try {
        const response = await fetch('https://79ac-139-135-43-100.ngrok-free.app/uploads/uploads', requestOptions);

        if (response.ok) {
          const result = await response.json();
          console.log('Property added successfully:', result);
          setSuccess("Property added successfully");
          navigate("/feature");
          setFormData({ select_and_upload: '', videoUrl: '' });
        } else {
          const result = await response.text();
          console.error('Error adding property:', result);
          setSuccess(null);
        }
      } catch (error) {
        console.error("Network error:", error);
      }
    }
  };

  return (
    <>
      <form>
        <div className='add-property'>
          <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
            <span className="form-span">Media</span>

            <div className="form-control">
              <label className="form-label">Select and Upload:</label>
              <div>
                <input
                  style={{ display: 'flex', width: '230px', height: "45px", padding: '14px' }}
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  placeholder="Select and upload"
                />
                {formErrors.select_and_upload && (
                  <span className="error-app">{formErrors.select_and_upload}</span>
                )}
                {inputFields[0].image && (
                  <img
                    src={URL.createObjectURL(inputFields[0].image)}
                    alt="Selected"
                    style={{ width: "100px", height: "auto", marginTop: "10px" }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
            <div>
              <div className="form-control">
                <label className='form-label' htmlFor="video-url">Video URL:</label>
                <input
                  className='form-input'
                  style={{ display: 'flex', width: 'auto' }}
                  type="text"
                  id="video-url"
                  value={formData.videoUrl}
                  onChange={handleVideoUrlChange}
                />
                {formErrors.videoUrl && (
                  <span className="error-app">{formErrors.videoUrl}</span>
                )}
              </div>
            </div>
          </div>
          <div className="form-wrapper" style={{ boxShadow: '0px 1px 3px -1px', marginTop: '12px', borderRadius: '9px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate("/")}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleNext}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Upload;
