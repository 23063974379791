import React, { useState, useRef } from 'react';

const CustomRangeSlider = ({ min = 0, max = 1000000, step = 10000, trackColor = "#ff0000", thumbColor = "#ff0000" }) => {
    const [minValue, setMinValue] = useState(min);
    const [maxValue, setMaxValue] = useState(max);

    const sliderRef = useRef(null);

    const handleThumbDrag = (event, thumb) => {
        const slider = sliderRef.current;
        const sliderRect = slider.getBoundingClientRect();
        const sliderWidth = sliderRect.width;
        const sliderLeft = sliderRect.left;
        const newPos = Math.min(Math.max(event.clientX - sliderLeft, 0), sliderWidth);
        const percentage = (newPos / sliderWidth) * 100;
        const newValue = Math.round((percentage / 100) * max);

        if (thumb === 'min') {
            setMinValue(Math.min(newValue, maxValue - step));
        } else {
            setMaxValue(Math.max(newValue, minValue + step));
        }
    };

    const handleMinInputChange = (e) => {
        const value = Math.min(Math.max(Number(e.target.value), min), maxValue - step);
        setMinValue(value);
    };

    const handleMaxInputChange = (e) => {
        const value = Math.max(Math.min(Number(e.target.value), max), minValue + step);
        setMaxValue(value);
    };

    return (
        <div className="custom-slider-container">

            {/* Input fields to set the min and max values */}
            <div className="input-range-values">
                <input
                    className="input-fields"
                    type="number"
                    value={minValue}
                    onChange={handleMinInputChange}  // Update min value when user types
                />
                <h3>TO</h3>
                <input
                    className="input-fields"
                    type="number"
                    value={maxValue}
                    onChange={handleMaxInputChange}  // Update max value when user types
                />
            </div>

            {/* The slider track and thumbs */}
            <div className="slider" ref={sliderRef}>
                <div
                    className="slider-track"
                    style={{ left: `${(minValue / max) * 100}%`, width: `${((maxValue - minValue) / max) * 100}%` }}
                />
                <div
                    className="slider-thumb min"
                    style={{ left: `${(minValue / max) * 100}%` }}
                    onMouseDown={(event) => {
                        const handleMove = (e) => handleThumbDrag(e, 'min');
                        document.addEventListener('mousemove', handleMove);
                        document.addEventListener('mouseup', () => {
                            document.removeEventListener('mousemove', handleMove);
                        });
                    }}
                />
                <div
                    className="slider-thumb max"
                    style={{ left: `${(maxValue / max) * 100}%` }}
                    onMouseDown={(event) => {
                        const handleMove = (e) => handleThumbDrag(e, 'max');
                        document.addEventListener('mousemove', handleMove);
                        document.addEventListener('mouseup', () => {
                            document.removeEventListener('mousemove', handleMove);
                        });
                    }}
                />
            </div>

            {/* Display the selected min and max values */}
            <div className="range-values">
                <span className="range-min">${minValue.toLocaleString()}</span>
                <span className="range-max">${maxValue.toLocaleString()}</span>
            </div>
        </div>
    );
};

export default CustomRangeSlider;
